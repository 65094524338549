import React from 'react'
import classNames from 'classnames'

const DescriptionWrap = ({ description }) => (
  <>
    {description.contentTitle && (
      <div className="description-wrapper">
        <div className="container">
          <h2>{description.contentTitle}</h2>
          <img src={description.image} alt={description.altText} />
          {description.contentDescription.map((descriptionItem, l) => {
            return (
              <div key={l} className="description-holder">
                <p className="title">{descriptionItem.title}</p>
                <p>{descriptionItem.text}</p>
              </div>
            )
          })}
          <div className="apply-now-wrapper">
            <div>
              <h2>{description.applyTitle}</h2>
              <p>{description.applyDescription}</p>
            </div>
            <button className={classNames('btn-orange', description.applyIntercom)}>Apply now</button>
          </div>
        </div>
      </div>
    )}
  </>
)

export default DescriptionWrap