import React from "react"

import List from "../list"
import Testimonial from "../testimonial"
import YouDontGet from "../you-dont-get"
import BadgeContent from "../badge-component"

import { pricingGlobalContent } from "../../lib/swiper-pricing-content"

const GlobalContent = () => (
  <div className="content-wrapper">
    <h2 className="heading">
      All Billsby plans include our incredible features
    </h2>
    <List content={pricingGlobalContent.listContent} />
    <List content={pricingGlobalContent.listContent2} />
    <List content={pricingGlobalContent.listContent3} />
    <List content={pricingGlobalContent.listContent4} />
    <List content={pricingGlobalContent.listContent5} />
    <List content={pricingGlobalContent.listContent6} />
    <div className="image-wrapper">
      <img src={require("../../images/pricing-banner.svg")} alt="pricing-banner" />
    </div>
    {/* <YouDontGet content={pricingGlobalContent.youDontGetContent} /> */}
    <div className="section-badges">
      <h2>
        More than 3,000 companies trust Billsby’s award winning billing platform
      </h2>
      <BadgeContent content={pricingGlobalContent.badgeData} />
    </div>
    <Testimonial />
  </div>
)

export default GlobalContent
