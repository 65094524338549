import React from 'react'
import { useLocation } from "@reach/router"

import BoxWrap from "./boxWrap"
import PaymentGateway from "../payment-gateway"
import DescriptionWrap from "./descriptionWrap"
import GlobalContent from "./globalContent"
import FreeTrial from "../free-trial"

const TabContent = ({ content }) => {
  let location = useLocation()
  const freeTrialContent = {
    title: "Get started with Billsby today",
    description:
      <>You can sign up to Billsby for as little as $35 per month, including $15,000 per month in transaction value. <br /> The best way to get to know Billsby is to try it for yourself.</>,
    svg: true,
    image: require("../../images/free-trial.svg"),
    imageName: "free-trial image",
    buttons: [
      {
        url: `${location.pathname === '/pricing' || location.pathname === '/pricing/' ? "https://app.billsby.com/registration" : ''}`,
        buttonColor: "orange",
        buttonText: "Get started with your account today",
        isExternal: true,
      },
      {
        scheduleButton: true,
        buttonColor: "black",
      },
    ],
  }

  const paymentProcessingContent = {
    layout: 1,
    title: "Bring a gateway, then change gateways anytime",
    titleUS: "Bring a gateway, or let us introduce you for preferred rates",
    textUS: "We’ve partnered with some of the best payment gateways around to give you excellent rates, priority underwriting and exceptional customer service - with easy integration into your Billsby account",
    offers: [
      {
        id: "low-risk",
        title: "Low-risk merchants",
        titleLayout2: "low risk merchants",
        withPopUp: false,
        popUpContent: <></>,
        boxContentLeft: {
          title: 'Payment processing',
          subtitle: <>
            <img className="paymentcloud-logo" src={require('../../images/paymentcloud-white@2x.png')} alt="Payment Cloud" />
            <img className="cardconnect-logo" src={require('../../images/cardconnect-white.svg')} alt="Card Connect" />
          </>
        },
        boxContentRight: {
          layout: 1,
          items: [
            {
              valueBig: '2.',
              valueStrong: '65%',
              valueNormal: ' + $0.15',
              label: 'For Visa, MasterCard and Discover',
              withPlus: false,
            },
            {
              valueBig: '2.',
              valueStrong: '9%',
              valueNormal: ' + $0.10',
              label: 'For American Express',
              withPlus: false,
            },
          ],
          description: <>For qualified businesses. No monthly fees. No setup fees. $20 minimum monthly charge. <span>Further discounts and Interchange+ pricing available for volumes over $25,000 per month.</span> Terms apply. See the link in your Billsby account for details.</>
        },
      },
      {
        id: "high-risk",
        title: "High-risk merchants",
        titleLayout2: "high risk merchants",
        withPopUp: true,
        popUpContent: <>
          <p>Merchants in industries like investment and credit services, money and legal services, gambling, regulated products and services (tobacco, cannabis, CBD and the like), adult content and services, network marketing, nutraceuticals, social media activity and video game or virtual world credits are often classified as ‘high risk’, because it’s more likely they’ll encounter chargebacks. It’s not a moral or judgement call, it’s just the statistics.</p>
          <p>These merchants tend to need specialist underwriting, and will pay a higher fee than merchants in other categories at first, with the chance of a rate review down the line.</p>
        </>,
        boxContentLeft: {
          title: 'Payment processing',
          subtitle: <>
            <span>Powered by </span><img className="paymentcloud-logo" src={require('../../images/paymentcloud-white@2x.png')} alt="Payment Cloud" />
          </>
        },
        boxContentRight: {
          layout: 1,
          items: [
            {
              valueBig: '3.',
              valueStrong: '95%',
              valueNormal: ' + $0.25',
              label: 'Typical merchant account rate',
              withPlus: true,
            },
            {
              valueBig: '$19',
              valueStrong: '',
              valueNormal: ' + $0.10',
              label: 'Typical payment gateway rate',
              withPlus: false,
            }
          ],
          description: <><span>Plus $49 monthly fee for merchant account.</span> Typical rate for eligible high-risk merchants. Underwriting required. Well-established businesses with low chargeback rates may qualify for preferred pricing. $19 per month plus $0.10 per transaction applies to typical pricing for Authorize.net account acquired through PaymentCloud. Full terms and application form in your Billsby account.</>
        },
      },
      {
        id: "high-volume",
        title: "Merchants with trading history",
        titleLayout2: "high volume merchants",
        withPopUp: false,
        popUpContent: <></>,
        boxContentLeft: {
          title: 'Payment processing',
          subtitle: <><span>For</span><span className="bold">merchants processing higher volumes</span></>
        },
        boxContentRight: {
          layout: 2,
          gatewayList: [
            {
              img: require('../../images/cardconnect-gray.svg'),
              cardConnect: true,
              imgAlt: 'Card Connect',
              desc: 'For clients processing $25,000+ per month',
            },
            {
              img: require('../../images/paymentcloud@2x.png'),
              paymentCloud: true,
              imgAlt: 'Payment Cloud',
              desc: 'For clients of all sizes with established trading history',
            },
          ],
          description: <>Preferred Interchange+ pricing is available to save you money on your transactions based on your industry sector and past chargeback rate. In your account, ask for an introduction to our recommended gateways.</>
        },
      },
    ],
    merchantsTitle: "Or bring your own payment gateway and merchant account - you can switch anytime",
    merchantsTitleNonUS: "Or bring your own payment gateway and merchant account - you can switch anytime",
    merchantsLogo: [
      {
        img: require('../../images/adyen-gray.svg'),
        gatewayName: "Adyen",
      },
      {
        img: require('../../images/authorizenet-gray.svg'),
        gatewayName: "Authorize.net",
      },
      {
        img: require('../../images/braintree-gray.svg'),
        gatewayName: "Braintree.com",
      },
      {
        img: require('../../images/Clover-Connect-Grey-Logo@2x.png'),
        gatewayName: "Clover Connect",
      },
      {
        img: require('../../images/checkout-gray.svg'),
        gatewayName: "Checkout",
      },
      {
        img: require('../../images/ixopay-gray.svg'),
        gatewayName: "IXOPAY",
      },
      {
        img: require('../../images/nmi-gray@2x.png'),
        gatewayName: "NMI",
      },
      {
        img: require('../../images/paymentcloud-gray@2x.png'),
        gatewayName: "Payment Cloud",
      },
      {
        img: require('../../images/stripe-gray.svg'),
        gatewayName: "Stripe",
      },
    ]
  }

  return (
    <>
      <BoxWrap box={content} />
      <PaymentGateway content={paymentProcessingContent} />
      <DescriptionWrap description={content} />
      <GlobalContent />
      <FreeTrial content={freeTrialContent} />
    </>
  )
}
export default TabContent