import React from "react"
import classNames from "classnames"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import GetInContactButton from "../get-in-contact-button"

const BoxWrap = ({ box }) => {
  return (
    <div className="box-wrapper">
      {box.box.map((boxContent, j) => {
        return (
          <div
            key={j}
            className={classNames("box", {
              "with-border": boxContent.withBorder === true,
            })}
          >
            {boxContent.withBorder === true && (
              <div className="label">{boxContent.labelText}</div>
            )}
            <h2>{boxContent.boxTitle}</h2>
            <p>{boxContent.description}</p>
            <div className="list-wrapper">
              {boxContent.packageList && (
                <>
                  {boxContent.packageList.map((itemList, k) => {
                    return (
                      <React.Fragment key={k}>
                        <p>
                          <span className="icon-check-circle">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="icon-check"
                            />
                          </span>
                          {itemList.list}
                        </p>
                      </React.Fragment>
                    )
                  })}
                </>
              )}
            </div>
            {boxContent.withButton && (
              <>
                <div className="btn-holder">
                  {/* <a
                      href={boxContent.signUpUrl}
                      // rel="noopener noreferrer"
                      // target="_blank"
                      className="btn-orange"
                    >
                      $99 one time fee
                    </a> */}
                  <Link to={boxContent.signUpUrl} className="btn-orange">
                    Sign up today
                  </Link>
                  <img
                    src={require("../../images/best-est-roi-winter.svg")}
                    alt="best-est-roi-winter"
                  />
                </div>
                <p className="text-sm">Get started with Billsby today</p>
              </>
            )}
            {boxContent.withModalPopUpButton && (
              <>
                <div className="btn-holder">
                  <GetInContactButton
                    buttonColor="black"
                    buttonText="Get in contact"
                    withModal={boxContent.withModalPopUpButton}
                  />
                </div>
                <p className="text-sm">Contact us to get a custom quote</p>
              </>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default BoxWrap
