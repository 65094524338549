import React from 'react'
import { Link } from 'gatsby'

export const swiperPricingContent = [
  {
    tab: 'forEveryone',
    tabTitle: 'For everyone',
    // dataHash: '',
    box: [
      {
        withButton: true,
        signUpUrl: 'https://app.billsby.com/registration',
        withBorder: true,
        labelText: 'Most popular',
        boxTitle: 'Complete',
        description: <>Completely free until you make $5,000 <br /> Then $0/month plus 0.4% transaction fee</>,
        packageList: [
          {
            list: 'Unlimited products, plans and cycles'
          },
          {
            list: <><span className="line-through">One</span>{" "}Unlimited payment gateways</>
          },
          {
            list: <><span className="line-through">One</span>{" "}Unlimited tax regions</>
          },
          {
            list: <><span className="line-through">Five</span>{" "}Unlimited currencies</>
          },
          {
            list: <><span className="line-through">Five</span>{" "}Unlimited team members</>
          },
        ],
      },
      {
        withModalPopUpButton: true,
        boxTitle: 'Managed',
        description: <>$2,000/month until you make $5,000 <br /> Then $2,000/month plus 0.4% transaction fee</>,
        packageList: [
          {
            list: 'Unlimited products, plans and cycles'
          },
          {
            list: 'Unlimited payment gateways'
          },
          {
            list: 'Unlimited tax regions'
          },
          {
            list: 'Unlimited currencies'
          },
          {
            list: 'Unlimited team members'
          },
          {
            list: 'Named account manager'
          },
          {
            list: <>Up to 50 hours of <Link to="/pro/development" className="underline">custom development</Link> per month</>
          },
          {
            list: 'Support SLA'
          }
        ]
      }
    ],
    forEveryoneTrial: true
  },
  {
    tab: 'earlyStage',
    tabTitle: 'Early stage startups',
    // dataHash: 'startups',
    signUpUrl: 'earlyStage',
    box: [
      {
        withButton: true,
        signUpUrl: 'https://app.billsby.com/registration?key=GD94QECOM1',
        boxTitle: 'Complete for Startups',
        description: <>Completely free until you make <span className="line-through">$5,000</span> <span className="orange-text">$10,000</span> <br /> Then $0/month plus 0.4% transaction fee</>,
        packageList: [
          {
            list: 'Unlimited products, plans and cycles'
          },
          {
            list: <><span className="line-through">One</span>{" "}Unlimited payment gateways</>
          },
          {
            list: <><span className="line-through">One</span>{" "}Unlimited tax regions</>
          },
          {
            list: <><span className="line-through">Five</span>{" "}Unlimited currencies</>
          },
          {
            list: <><span className="line-through">Five</span>{" "}Unlimited team members</>
          },
        ]
      },
      {
        withBorder: true,
        labelText: 'Save $4,000',
        boxTitle: 'Accelerator',
        description: <>Completely free for 12 months ($1,000,000 limit) <br />Then $0/month plus 0.4% transaction fee <br /> <br /> Exclusively for qualifying early stage startups - see criteria and application form below</>,
        packageList: [
          {
            list: 'Unlimited products, plans and cycles'
          },
          {
            list: 'Unlimited payment gateways'
          },
          {
            list: 'Unlimited tax regions'
          },
          {
            list: 'Unlimited currencies'
          },
          {
            list: 'Unlimited team members'
          }
        ]
      }
    ],
    contentTitle: 'Accelerating the future of the subscription economy for startups',
    contentDescription: [
      {
        title: 'A $500 million dollar fund',
        text: 'As we restart the economy and re-build business after the coronavirus pandemic, Billsby is committed to helping companies of all shapes and sizes benefit from the security of recurring revenue by growing or developing subscription offerings. This year, we’re waiving our processing fees on up to $500,000,000 of transactions for qualifying customers - like early stage startups, charities and non-profits, students and the industries most impacted by COVID-19. Our goal is to help thousands of businesses to grow and develop - building strong and stable subscription businesses this year.'
      },
      {
        title: 'Our accelerator community',
        text: 'More than just a transaction fee waiver - all successful Accelerator program applicants will become part of our accelerator community, with exclusive access to our private Slack with senior level Billsby colleagues to help them with their subscription business, and the wisdom of others in their cohort from communities all around the world.'
      },
      {
        title: 'Application criteria for startups',
        text: 'Qualifying startups have no more than ten employees, and have raised at least $250,000, but no more than $2,000,000 of venture funding or are current or alumni members of YCombinator, AngelPad, LaunchPad LA, Amplify.LA, Alphalab, 500 Startups, RevUp Capital, Portland Incubator Experiment, Upwest Labs, Rock Health, Capital Factory, Jumpstart Foundry, Entrpreneurs Roundtable Association, Blue Startups, Portland Seed Fund, NFX, SixThirty, Acceleprise, Boost VC, Capital Innovators, The Alchemist Accelerator, Mucker Lab, Generat8r, DreamIT Ventures, Techstars, StartupBootcamp, Orange Fab, Seed Round Capital, SeedCamp, Ignite, Bethnal Green Ventures, Startup Reykjavik, Metavallon, Egg, ProSiebenSat.1 Accelerator, Axel Springer Plug and Play Accelerator, Startup Wise Guys, Buildit Accelerator, Startup Yard, Chinaccelerator, HAX, Highline Beta, FounderFuel, Jolt, LAUNCHub, Eleven Startup Accelerator, Incubate, Innovyz, Melbourne Accelerator Program, Startmate, Nxtp.labs or a similarly well-known startup incubator or accelerator program. Inclusion on our list should not be inferred as or imply any relationship between Billsby and the listed incubators. Not all startups guaranteed approval. Billsby’s decision is final. Fee waiver expires after 12 months. No cash alternative.'
      }
    ],
    image: require("../images/pricing-early-stage.svg"),
    altText: 'Early Stage Image',
    applyTitle: <><span className="white-text">Apply for</span> accelerator for startups</>,
    applyDescription: 'Whilst you’re waiting to hear back, you can sign up for Billsby Complete for Startups. If your application is successful, we’ll automatically upgrade your account. You’ll hear back within 72 hours.',
    applyIntercom: 'intercom-accelerate-startups',
    earlyTrial: true
  },
  {
    tab: 'charities',
    tabTitle: 'Charities and non-profits',
    // dataHash: 'charities',
    signUpUrl: 'charities',
    box: [
      {
        withButton: true,
        signUpUrl: 'https://app.billsby.com/registration?key=NM0QZ7T7ME',
        boxTitle: 'Complete for Charities',
        description: <>Completely free until you make <span className="line-through">$5,000</span> <span className="orange-text">$10,000</span> <br /> Then $0/month plus 0.4% transaction fee</>,
        packageList: [
          {
            list: 'Unlimited products, plans and cycles'
          },
          {
            list: <><span className="line-through">One</span>{" "}Unlimited payment gateways</>
          },
          {
            list: <><span className="line-through">One</span>{" "}Unlimited tax regions</>
          },
          {
            list: <><span className="line-through">Five</span>{" "}Unlimited currencies</>
          },
          {
            list: <><span className="line-through">Five</span>{" "}Unlimited team members</>
          },
        ]
      },
      {
        withBorder: true,
        labelText: 'Save $100,000',
        boxTitle: 'Accelerator',
        description: <>Completely free for 12 months ($25,000,000 limit) <br />Then $0/month plus <span className="line-through">0.4%</span> <span className="orange-text">0.3% </span>transaction fee <br /> <br />Exclusively for qualifying charities and non-profits - see criteria and application form below</>,
        packageList: [
          {
            list: 'Unlimited products, plans and cycles'
          },
          {
            list: 'Unlimited payment gateways'
          },
          {
            list: 'Unlimited tax regions'
          },
          {
            list: 'Unlimited currencies'
          },
          {
            list: 'Unlimited team members'
          }
        ]
      }
    ],
    contentTitle: 'Helping charities engage with supporters in the subscription economy',
    contentDescription: [
      {
        title: 'A $500 million dollar fund',
        text: 'As we restart the economy and re-build business after the coronavirus pandemic, Billsby is committed to helping companies of all shapes and sizes benefit from the security of recurring revenue by growing or developing subscription offerings. This year, we’re waiving our processing fees on up to $500,000,000 of transactions for qualifying customers - like early stage startups, charities and non-profits, students and the industries most impacted by COVID-19. Our goal is to help thousands of businesses to grow and develop - building strong and stable subscription businesses this year.'
      },
      {
        title: 'Our accelerator community',
        text: 'More than just a transaction fee waiver - all successful Accelerator program applicants will become part of our accelerator community, with exclusive access to our private Slack with senior level Billsby colleagues to help them with their subscription business, and the wisdom of others in their cohort from communities all around the world.'
      },
      {
        title: 'Application criteria for charities and non-profits',
        text: 'New Billsby customers or existing Billsby customers who are currently in their free trial. Excludes current paying Billsby customers. Qualifying charities are recognised and registered charities and not-for-profits that are working towards societal good. Excludes purely political or lobbying groups. For US charities, must be in the GuideStar database or similar. For UK charities, must be registered with the Charities Commission. For other countries, similar rules and restrictions apply. Not all charities guaranteed approval. Billsby’s decision is final. Fee waiver expires after 12 months. No cash alternative.'
      }
    ],
    image: require("../images/pricing-charities.svg"),
    altText: 'Charities and non-profits Image',
    applyTitle: <><span className="white-text">Apply for</span> accelerator for charities</>,
    applyDescription: 'Whilst you’re waiting to hear back, you can sign up for Billsby Complete for Charities. If your application is successful, we’ll automatically upgrade your account. You’ll hear back within 72 hours.',
    applyIntercom: 'intercom-accelerate-charities',
    charitiesTrial: true
  },
  {
    tab: 'migrators',
    tabTitle: 'Migrators',
    // dataHash: 'migrators',
    signUpUrl: 'migrators',
    box: [
      {
        withButton: true,
        signUpUrl: 'https://app.billsby.com/registration',
        boxTitle: 'Core',
        description: <>Completely free until you make $5,000 <br />Then $0/month plus 0.4% transaction fee</>,
        packageList: [
          {
            list: 'Unlimited products, plans and cycles'
          },
          {
            list: <><span className="line-through">One</span>{" "}Unlimited payment gateways</>
          },
          {
            list: <><span className="line-through">One</span>{" "}Unlimited tax regions</>
          },
          {
            list: <><span className="line-through">Five</span>{" "}Unlimited currencies</>
          },
          {
            list: <><span className="line-through">Five</span>{" "}Unlimited team members</>
          },
        ]
      },
      {
        withBorder: true,
        labelText: 'Save $8,000',
        boxTitle: 'Migration Bonus',
        description: <>For all managed inbound migrations of more than 100 customers or $25,000 MRR started in 2021 benefit from: <br /> <br /> Free managed migration (save $4,000) <br /> <br /> $1,000,000 transaction fee credit (save $4,000) <br /> <br />Full qualifying criteria and contact details below. For new and existing customers.</>,
      }
    ],
    contentTitle: 'Making migrations simple, quick and hassle free',
    contentDescription: [
      {
        title: 'Managed migrations are quick, easy and seamless',
        text: <>With our managed migration process, we can move hundreds of thousands of accounts with ease from your existing PCI-DSS compliant subscription billing provider - and your customers won’t even notice the change. <br /> <br />We’ll map customers payment cards to their new Billsby profiles and make sure their charges continue on the same day they’re used to. <br /> <br />The whole process is simple and easy.</>
      },
      {
        title: 'Switch to a new payment gateway, or keep your old one',
        text: <>If you use one of our <Link to="/product/payment-gateways/" className="underline">supported payment gateways</Link>, you’ll be able to stick with it. Or you can switch to a new one if you’d prefer. Whatever you decide, because your cards will be securely held in the Billsby Vault, you’ll be able to switch payment gateways whenever you want in the future.</>
      },
      {
        title: 'Migration bonus terms',
        text: 'First time migrations who have not benefitted from any other offer or bonus beyond our standard $5,000 free trial. Migration must be started before March 31 2021. Customer must migrate at least 100 customers or $25,000 of MRR from billforward, billwerk, Cerillion Skyline, Chargebee, ChargeOver, Chargify, FastSpring, Fusebill, LogiSense, Odoo Subscriptions, Ordway, Paddle, PayKickstart, PayMotion, PayPro Global, PaySimple, Rebilly, ReCharge, Securely, RecVue, Rerun, Rev.io, SaaSoptics, Stripe Billing, Zoho Subscriptions, Zuora or a similar billing platform or in-house system. Not all migrations guaranteed approval. Billsby’s decision is final. Fee waiver expires after 12 months. No cash alternative.'
      }
    ],
    image: require("../images/pricing-migrators.svg"),
    altText: 'Migrators Image',
    applyTitle: <><span className="white-text">Talk to</span> our managed migrations team</>,
    applyDescription: 'Our team would be happy to talk you through arranging a managed migration for your business that saves you money and keeps your customers happy!',
    applyIntercom: 'intercom-migrationbonus',
    migratorsTrial: true
  },
  {
    tab: 'students',
    tabTitle: 'Students',
    // dataHash: 'students',
    box: [
      {
        withButton: true,
        signUpUrl: 'https://app.billsby.com/registration?key=RW34RJCQD2',
        boxTitle: 'Complete for School Students',
        description: <>Completely free until you make <span className="line-through">$5,000</span> <span className="orange-text">$10,000</span> <br /> Then $0/month plus 0.4% transaction fee</>,
        packageList: [
          {
            list: 'Unlimited products, plans and cycles'
          },
          {
            list: <><span className="line-through">One</span>{" "}Unlimited payment gateways</>
          },
          {
            list: <><span className="line-through">One</span>{" "}Unlimited tax regions</>
          },
          {
            list: <><span className="line-through">Five</span>{" "}Unlimited currencies</>
          },
          {
            list: <><span className="line-through">Five</span>{" "}Unlimited team members</>
          },
        ]
      },
      {
        withBorder: true,
        labelText: 'Save $4,000',
        boxTitle: 'Accelerator',
        description: <>Completely free for 12 months ($1,000,000 limit) <br />Then $0/month plus 0.4% transaction fee <br /><br />Exclusively for qualifying university/college level students - see criteria and application form below</>,
        packageList: [
          {
            list: 'Unlimited products, plans and cycles'
          },
          {
            list: <><span className="line-through">One</span>{" "}Unlimited payment gateways</>
          },
          {
            list: <><span className="line-through">One</span>{" "}Unlimited tax regions</>
          },
          {
            list: <><span className="line-through">Five</span>{" "}Unlimited currencies</>
          },
          {
            list: <><span className="line-through">Five</span>{" "}Unlimited team members</>
          },
        ]
      }
    ],
    contentTitle: 'Accelerating the future of the subscription economy for students',
    contentDescription: [
      {
        title: 'A $500 million dollar fund',
        text: 'As we restart the economy and re-build business after the coronavirus pandemic, Billsby is committed to helping companies of all shapes and sizes benefit from the security of recurring revenue by growing or developing subscription offerings. This year, we’re waiving our processing fees on up to $500,000,000 of transactions for qualifying customers - like early stage startups, charities and non-profits, students and the industries most impacted by COVID-19. Our goal is to help thousands of businesses to grow and develop - building strong and stable subscription businesses this year.'
      },
      {
        title: 'Our accelerator community',
        text: 'More than just a transaction fee waiver - all successful Accelerator program applicants will become part of our accelerator community, with exclusive access to our private Slack with senior level Billsby colleagues to help them with their subscription business, and the wisdom of others in their cohort from communities all around the world.'
      },
      {
        title: 'Application criteria for students',
        text: 'New Billsby customers or existing Billsby customers who are currently in their free trial. Excludes current paying Billsby customers. Qualifying students are customers who sign up to Billsby with a recognised educational email address (.edu or local equivalent) and are able to provide corroborating student ID associated with the email address to our student team, such as student or faculty ID, screenshot of student or faculty profile page, most recent report card or transcript, enrolment certificate/verification or relevant employment letter. Not all students guaranteed approval. Billsby’s decision is final. Fee waiver expires after 12 months. No cash alternative.'
      },
    ],
    image: require("../images/pricing-students.svg"),
    altText: 'Students Image',
    applyTitle: <><span className="white-text">Apply for</span> accelerator for students</>,
    applyDescription: 'Whilst you’re waiting to hear back, you can sign up for Billsby Complete for Students. If your application is successful, we’ll automatically upgrade your account. You’ll hear back within 72 hours.',
    applyIntercom: 'intercom-accelerate-students',
    studentsTrial: true
  },
  {
    tab: 'covidRelief',
    tabTitle: 'COVID Relief',
    // dataHash: 'covid-relief',
    signUpUrl: 'covidRelief',
    box: [
      {
        withButton: true,
        signUpUrl: 'https://app.billsby.com/registration?key=GDN1Y2U8MR',
        boxTitle: 'Complete with COVID Bonus',
        description: <>Completely free until you make <span className="line-through">$5,000</span> <span className="orange-text">$10,000</span> <br /> Then $0/month plus 0.4% transaction fee</>,
        packageList: [
          {
            list: 'Unlimited products, plans and cycles'
          },
          {
            list: <><span className="line-through">One</span>{" "}Unlimited payment gateways</>
          },
          {
            list: <><span className="line-through">One</span>{" "}Unlimited tax regions</>
          },
          {
            list: <><span className="line-through">Five</span>{" "}Unlimited currencies</>
          },
          {
            list: <><span className="line-through">Five</span>{" "}Unlimited team members</>
          },
        ]
      },
      {
        withBorder: true,
        labelText: 'Save $4,000',
        boxTitle: 'Accelerator',
        description: <>Completely free for 12 months ($1,000,000 limit) <br />Then $0/month plus 0.4% transaction fee <br /><br />Exclusively for industries adversely affected by COVID-19 - see criteria and application form below</>,
        packageList: [
          {
            list: 'Unlimited products, plans and cycles'
          },
          {
            list: 'Unlimited payment gateways'
          },
          {
            list: 'Unlimited tax regions'
          },
          {
            list: 'Unlimited currencies'
          },
          {
            list: 'Unlimited team members'
          },
        ]
      }
    ],
    contentTitle: 'Assisting entry to the subscription economy for COVID impacted businesses',
    contentDescription: [
      {
        title: 'A $500 million dollar fund',
        text: 'As we restart the economy and re-build business after the coronavirus pandemic, Billsby is committed to helping companies of all shapes and sizes benefit from the security of recurring revenue by growing or developing subscription offerings. This year, we’re waiving our processing fees on up to $500,000,000 of transactions for qualifying customers - like early stage startups, charities and non-profits, students and the industries most impacted by COVID-19. Our goal is to help thousands of businesses to grow and develop - building strong and stable subscription businesses this year.'
      },
      {
        title: 'Our accelerator community',
        text: 'More than just a transaction fee waiver - all successful Accelerator program applicants will become part of our accelerator community, with exclusive access to our private Slack with senior level Billsby colleagues to help them with their subscription business, and the wisdom of others in their cohort from communities all around the world.'
      },
      {
        title: 'Application criteria for COVID impacted businesses',
        text: 'New Billsby customers or existing Billsby customers who are currently in their free trial. Excludes current paying Billsby customers. Businesses in the accommodation and food, recreational services, wholesale and retail, admin and support, manufacturing or real estate industries with at least ten but fewer than one thousand employees who attest that their business has suffered from a reduction in revenue of at least 25% since the start of the pandemic. Not all businesses that meet this criteria are guaranteed approval. Billsby’s decision is final. Fee waiver expires after 12 months. No cash alternative.'
      }
    ],
    image: require("../images/pricing-covid.svg"),
    altText: 'Covid Relief Image',
    applyTitle: <><span className="white-text">Apply for</span> accelerator for COVID impacted businesses</>,
    applyDescription: 'Whilst you’re waiting to hear back, you can sign up for Billsby Complete with COVID Bonus. If your application is successful, we’ll automatically upgrade your account. You’ll hear back within 72 hours.',
    applyIntercom: 'intercom-accelerate-covid',
    covidTrial: true
  }
]

export const pricingContent = {
  tabNav:[
    {
      item: 'For everyone',
      link: '',
    }
  ],
  everyoneContent: {
    box: [
      {
        withButton: true,
        signUpUrl: 'https://app.billsby.com/registration',
        withBorder: true,
        labelText: 'Most popular',
        boxTitle: 'Core',
        description: <><span className="text-bold">Only $35/mo</span><br />plus 0.4% on revenue exceeding $15,000/mo</>,
        packageList: [
          {
            list: '1 month free trial period'
          },
          {
            list: 'Unlimited products, plans and cycles'
          },
          {
            list: 'Multiple payment gateways'
          },
          {
            list: 'Multi-currency'
          },
          {
            list: 'Unlimited users'
          },
          {
            list: 'Sales tax automation'
          },
        ],
      },
      {
        withModalPopUpButton: true,
        boxTitle: 'Custom',
        description: <><span className="text-bold">Bespoke complex solutions for high volume customers</span> <br /> <br /> Negotiated pricing</>,
      }
    ],
    forEveryoneTrial: true
  }
}

export const pricingGlobalContent = {
  listContent: {
    title: "Create products, plans and cycles",
    backgroundImage: require("../images/list-title-bg1@2x.png"),
    listFirstSet: [
      {
        title: "Free trials",
      },
      {
        title: "Setup fees",
      },
      {
        title: "Contact terms",
      },
      {
        title: "Pro-rating",
      },
      {
        title: "Feature tags",
      },
    ],
    listSecondSet: [
      {
        title: "Add-ons",
      },
      {
        title: "Allowances and counters",
      },
      {
        title: "Flat-fee, per unit, volume, ranged and tiered plans",
      },
      {
        title: "Coupon codes and discounts",
      },
    ],
  },
  listContent2: {
    title: "Branding and customization",
    backgroundImage: require("../images/list-title-bg2@2x.png"),
    listFirstSet: [
      {
        title: "Branded invoices",
      },
      {
        title: "Branded emails",
      },
      {
        title: "Branded checkout and account management",
      },
      {
        title: "Custom taxonomy",
      },
      {
        title: "Include adverts in emails and invoices",
      },
    ],
    listSecondSet: [
      {
        title: "Integrate support channels",
      },
      {
        title: "Custom email copy",
      },
      {
        title: "Custom email addresses",
      },
    ],
  },
  listContent3: {
    title: "Advanced billing tools",
    backgroundImage: require("../images/list-title-bg3@2x.png"),
    listFirstSet: [
      {
        title: "Billsby Checkout",
      },
      {
        title: "Card tokenizer",
      },
      {
        title: "Custom fields",
      },
      {
        title: "Customer data pass through",
      },
      {
        title: "Unlimited payment gateways",
      },
    ],
    listSecondSet: [
      {
        title: "Billsby Vault",
      },
      {
        title: "Global tax compliance",
      },
      {
        title: "Advanced invoices and credit notes",
      },
    ],
  },
  listContent4: {
    title: "Self-service and retention",
    backgroundImage: require("../images/list-title-bg4@2x.png"),
    listFirstSet: [
      {
        title: "Self-services account management",
      },
      {
        title: "Advanced dunning journeys",
      },
      {
        title: "Retention tools",
      },
    ],
  },
  listContent5: {
    title: "Metrics and reporting",
    backgroundImage: require("../images/list-title-bg5@2x.png"),
    listFirstSet: [
      {
        title: "Billsby Dashboard",
      },
      {
        title: "Billsby Briefing",
      },
      {
        title: "Activity summary",
      },
      {
        title: "Checkout performance",
      },
      {
        title: "Customer and subscription summaries",
      },
    ],
    listSecondSet: [
      {
        title: "Exit reasons",
      },
      {
        title: "Product, plan and cycle performance",
      },
      {
        title: "Tax summary",
      },
      {
        title: "Unpaid invoices",
      },
    ],
  },
  listContent6: {
    title: "Powerful integrations",
    backgroundImage: require("../images/list-title-bg6@2x.png"),
    listFirstSet: [
      {
        title: "Accounting",
      },
      {
        title: "API and web hooks",
      },
      // {
      //   title: "Customer services",
      // },
      // {
      //   title: "Plan actions",
      // },
      {
        title: "Zapier",
      },
    ],
  },
  youDontGetContent: {
    title: (
      <>
        Here's what you <span>don't</span> get with Billsby
      </>
    ),
    gridListing: [
      {
        title: "Time limited free trials",
        description:
          "We believe in the power of our platform, and we want you to have as much time as you'd like to try it for yourself – so you can keep your sandbox account forever, and we'll even let you make $5,000 on us before you pay a cent in transaction fees.",
      },
      {
        title: "Expensive monthly minimum fees",
        description:
          "We only want to make money when you make money – so there's no monthly minimum fee to use Billsby, and our transaction fee is really competitive at just 0.4%. We do charge fees for some add-ons, but they're completely optional – just pay for what your business needs to succeed.",
      },
      {
        title: "Transaction fees that go up as your business grows",
        description:
          "Our transaction fee is fixed at 0.4% – low enough to support the growth of your business, and high enough that we don't need to lock features behind more expensive premium plans.",
      },
      {
        title: "Pushy sales calls",
        description:
          "We believe in our product, so you can sign up for a free trial without speaking to a member of the team, and upgrade to a paid plan whenever you're ready. We might call you to ask how things are going, but we'll never make a call a condition of getting started or using the platform.",
      },
    ],
    listing: [
      {
        title: "$5,000 free trial",
        description:
          "For the first $5,000 of revenue collected through Billsby, no transaction fee will apply – excl. additional fees, Billsby Pro and enterprise agreements",
      },
      {
        title: "Additional fees",
        description:
          "Address validation ($0.05 per address validated) and tax calculation ($0.02 per calculation completed)",
      },
    ],
  },
  badgeData: {
    section: "compared",
    link: false,
    FallBadges: [
      {
        img: require("../images/best-relationship-total-2022.svg"),
        altText: "best-relationship-total-2022",
      },
      {
        img: require("../images/most-implementable-winter-2022.svg"),
        altText: "most-implementable-winter-2022",
      },
      {
        img: require("../images/momentum-leader-winter-2022.svg"),
        altText: "momentum-leader-winter-2022",
      },
      {
        img: require("../images/high-performer-winter-2022.svg"),
        altText: "high-performer-winter-2022",
      },
      {
        img: require("../images/best-usability-winter-2022.svg"),
        altText: "best-usability-winter-2022",
      },
      {
        img: require("../images/fastest-implementation-fall.svg"),
        altText: "fastest-implementation-fall",
      },
      {
        img: require("../images/best-meets-requirements-winter-2022.svg"),
        altText: "best-meets-requirements-winter-2022",
      },
      {
        img: require("../images/best-est-roi-winter.svg"),
        altText: "best-est-roi-winter",
      },
    ],
    SmallBussinessBadge: [
      {
        img: require("../images/best-relationship-small-business-2022.svg"),
        altText: "best-relationship-small-business-2022",
      },
      {
        img: require("../images/best-usability-small-business-2022.svg"),
        altText: "best-usability-small-business-2022",
      },
      {
        img: require("../images/easiest-setup.svg"),
        altText: "easiest-setup",
      },
      {
        img: require("../images/best-est-roi-small-business-2021.svg"),
        altText: "best-est-roi-small-business-2021",
      },
      {
        img: require("../images/high-performer-small-business-2022.svg"),
        altText: "high-performer-small-business-2022",
      },
      {
        img: require("../images/users-most-likely-to-recommend-2021.svg"),
        altText: "users-most-likely-to-recommend-2021",
      },
      {
        img: require("../images/fastest-implementation-2021.svg"),
        altText: "fastest-implementation-2021",
      },
      {
        img: require("../images/best-meets-requirements-small-business-2022.svg"),
        altText: "best-meets-requirements-small-business-2022",
      },
      {
        img: require("../images/easiest-admin-2021.svg"),
        altText: "easiest-admin-2021",
      },
      {
        img: require("../images/easiest-to-use.svg"),
        altText: "easiest-to-use",
      },
    ],
  }
}